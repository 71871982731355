import React from "react";

const Hero = () => {
  return (
    <section
      className="hero banner d-flex align-items-center justify-content-center text-white text-center position-relative"
      style={{
        height: "100vh",
        overflow: "hidden", // Ensures content fits within the section
      }}
    >
      {/* Blurred Background Image */}
      <div
        className="background-image"
        style={{
          backgroundImage: `url(assets/img/bg-home-1.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // filter: "blur(1px)",
          zIndex: -1,
        }}
      ></div>

      {/* Content on top of the blurred image */}
      <div className="container">
        <h2 className="">
          2nd International Conference on Advanced Computing & Communication
          Technologies
        </h2>
        <p className="lead">(Hybrid Mode)</p>
        <div className="conference-date text-white py-2 px-4 mt-4 rounded">
          <h3>16th & 17th November 2024</h3>
        </div>
        <div className="conference-organised mt-3 h5 font-weight-medium">
          <h1>Ethnic India, Rai, Sonipat, Delhi - NCR, India</h1>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <a
            className="nav-link link-button scrollto px-1"
            target="_blank"
            href="https://cmt3.research.microsoft.com/ACCT2024"
          >
            <button className="btn btn-light nav-btn-left nav-btn-mobile">
              Paper Submission
            </button>
          </a>

          <a
            className="nav-link link-button scrollto px-1"
            target="_blank"
            href="https://acctcomputing.netlify.app/"
          >
            <button className="btn btn-light nav-btn nav-btn-mobile">
              Registration
            </button>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
