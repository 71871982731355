import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";

const Header = ({ aboutRef, mainRef, nomiRef, dateRef }) => {
  const ref = useRef(null);

  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    const div = document.querySelector("#navbar");
    div.classList.add("navbar");
    div.classList.remove("navbar-mobile");
  };

  const handleScrollAbout = (ref) => {
    window.scrollTo({
      top: ref.offsetTop + 700,
      left: 0,
      behavior: "smooth",
    });
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar-mobile");
    div.classList.add("navbar");
  };

  return (
    <div>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-center">
          <div className="logo">
            <a href="/">
              <img
                src="assets/img/logo.png"
                alt=""
                className="img-fluid logo-img1"
              />
            </a>
          </div>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <Link className="nav-link link-button scrollto" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link class="nav-link link-button scrollto" to="/cfp">
                  Call for Papers
                </Link>
              </li>
              <li>
                <Link class="nav-link link-button scrollto" to="/guidelines">
                  Guidelines
                </Link>
              </li>
              <li>
                <Link class="nav-link link-button scrollto" to="/committee">
                  Committee
                </Link>
              </li>
              <li>
                <Link
                  class="nav-link link-button scrollto"
                  to="/special-session"
                >
                  Special Session
                </Link>
              </li>
              <li>
                <Link class="nav-link link-button scrollto" to="/past-conf">
                  Past Conferences
                </Link>
              </li>
              {/* <li>
                <a
                  className="nav-link link-button scrollto"
                  target="_blank"
                  href="https://cmt3.research.microsoft.com/ACCT2024"
                >
                  <button className="btn btn-light nav-btn-left nav-btn-mobile">
                    Paper Submission
                  </button>
                </a>
              </li> */}
              <li>
                <a
                  className="nav-link link-button scrollto"
                  target="_blank"
                  href="https://acctcomputing.netlify.app/"
                >
                  <button className="btn btn-light nav-btn nav-btn-mobile">
                    Registration
                  </button>
                </a>
              </li>
            </ul>
            <button onClick={mobileNavView} className="mobile-nav-toggle">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/FFFFFF/menu--v1.png"
                alt="menu--v1"
              />
            </button>
          </nav>
          <div className="logo px-4">
            <a href="/">
              <img
                src="assets/img/logo1.png"
                alt=""
                className="img-fluid logo-img1"
              />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
